<template>
    <v-row v-if="isMobile" class="mt-n6 pa-0">
        <v-col justify="space-around" cols="12" class="mini-hero pa-0 px-5">
          <mini-hero></mini-hero>
          <agro-categories-group />
        </v-col>
<!--        <v-row justify="center">-->
<!--            <v-col md="12" class="mb-16 px-0">-->
<!--                <trending-card></trending-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-row>
    <v-row v-else class="mt-n7 mb-16">
        <v-col justify="space-around">
            <v-col cols="12" class="mt-1 mb-5 pa-0">
                <hero></hero>
<!--                <trending-card></trending-card>-->
                <agro-categories-group />
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
    import MiniHero from '@/components/Website/MiniHero'
    import Hero from '@/components/Website/Hero'
    import TrendingCard from '@/components/Website/Trending/TrendingCard';
    import AutoSearch from "@/components/Website/AutoSearch";
    import ChipCategory from "../../components/Website/Trending/ChipCategory";
    import AgroCategoriesGroup from "@/components/Website/AgroCategories/AgroCategoriesGroup.vue";
    import mixpanel from 'mixpanel-browser';

    mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true});

    export default {
      name: "Home",
      data() {
        return {}
      },
      computed: {
        imgurl() {
          return storageRef.child('congratulations.png');

        },
      },
      components: {
        MiniHero,
        Hero,
        TrendingCard,
        AutoSearch,
        ChipCategory,
        AgroCategoriesGroup
      },

      mounted() {
        if(this.$route.params){
          // console.log('Data passed from App')
          // console.log(this.$route.params)
        }

        mixpanel.track("Website Visits", {
          website: 'visited',
        });
      }
    }
</script>

<style lang="scss" scoped>
   .mini-hero{
     background: 'back';
   }


    .side-bus{
    /*    position:fixed;*/
        border-radius:20px;
    }
    .box {
        align-self: flex-end;
        animation-duration: 2s;
        animation-delay: 5s;
        animation-timing-function: linear;
        animation-iteration-count:  1;
        background-color: #f0f0f0;
        margin: 0 auto 0 auto;
        transform-origin: bottom;
    }
    .bounce-5 {
        animation-name: bounce-5;
        animation-timing-function: ease;
    }
    @keyframes bounce-5 {
        0%   { transform: scale(1,1)    translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1) translateY(-100px); }
        50%  { transform: scale(1,1)    translateY(0); }
        57%  { transform: scale(1,1)    translateY(-7px); }
        64%  { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
    }

</style>
