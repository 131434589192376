<template>
  <div class="ma-0 pa-0">
    <v-row  justify="center">
      <v-col align="center" cols="11" xs="10" sm="10" md="10" lg="10">
          <middle-ware headline="Promoted Offers" :class="isMobile ? 'my-n3 mx-2': 'my-n3'"></middle-ware>
          <v-row justify="center" >
              <v-col v-for="(product, i) in productList" :key="i" cols="6"  sm="4" md="3" xl="3" lg="3" class="mx-0 mb-n2">
                  <new-product-card class="mt-8" :product_details="product"> </new-product-card>
              </v-col>
              <v-row class="mt-16" justify="center" v-if="loadingTrending">
                <v-progress-circular indeterminate :size="isMobile? 35:72" color="primary"></v-progress-circular>
              </v-row>
              <v-col align="center">
                <div v-if="productList.length" v-observe-visibility="handleScrollToBottom"></div>
                <div></div>
              </v-col>
          </v-row>
      </v-col>
    </v-row>

    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        left
        color="red darken-3"
        @click="toTop"
        class="topFab"
        :small="!!isMobile"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
    <v-bottom-navigation
        v-model="value"
        :input-value="active"
        color="indigo"
        fixed
    >
      <v-row class="mx-16 bottomFoot" v-if="!isMobile">
        <v-col cols="5" md="5">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                    class="green darken-2"
                    dark
                >
                  mdi-cellphone
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-weight: bold;font-size:23px">Dial *713*83#</v-list-item-title>
                <v-list-item-subtitle>To Place An Order</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="2" md="2" class="d-flex justify-center align-center">
<!--          <v-btn-->
<!--              fab-->
<!--              @click="sheet = !sheet"-->
<!--          >-->
<!--            <v-icon-->
<!--                class="grey lighten-1"-->
<!--                dark-->
<!--            >-->
<!--              mdi-arrow-up-->
<!--            </v-icon>-->
<!--          </v-btn>-->
          <v-list-item-avatar class="box bounce-4 mx-0" @click="sheet = !sheet">
            <v-icon
                class="green darken-2"
                dark
            >
              mdi-arrow-up
            </v-icon>
          </v-list-item-avatar>
        </v-col>
        <v-col cols="5" md="5">
          <v-row justify="end">
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon
                      class="green darken-2"
                      dark
                  >
                    mdi-phone
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-weight: bold;font-size:23px">+233 24 095 6276</v-list-item-title>
                  <!--                <v-list-item-subtitle style="font-weight: bold;font-size:23px">0551888118</v-list-item-subtitle>-->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
      <span v-else>
        <v-btn @click="callUssd">
          <span class="font-weight-bold black--text">*713*83#</span>
          <v-icon class="yellow--text text--darken-3">mdi-cellphone</v-icon>
        </v-btn>

        <v-list-item-avatar size="40" class="box bounce-4" @click="sheet = !sheet">
            <v-icon
                class="green darken-2"
                dark
            >
              mdi-arrow-up
            </v-icon>
          </v-list-item-avatar>


        <v-btn @click="callPhone">
          <span class="font-weight-bold black--text">+233 24 095 6276</span>
          <v-icon class="yellow--text text--darken-3">mdi-phone</v-icon>
        </v-btn>
      </span>
    </v-bottom-navigation>
    <v-bottom-sheet
        v-model="sheet"
    >
      <v-sheet
          class="text-center"
      >
        <v-btn
            class="mt-6"
            text
            color="error"
            @click="sheet = !sheet"
        >
          close
        </v-btn>
        <Footer />
      </v-sheet>
    </v-bottom-sheet>

  </div>

</template>
<script>
import Crud from '@/gql/PrismaCrud.gql'
const gqlProduct = new Crud('product')
import MiniProductCard from "../Product/MiniProductCard";
import NewProductCard from "../Product/NewProductCard";
import ProductCard from "../Product/ProductCard";
import MiddleWare from "../MiddleWare"
import SearchMiddle from "../core/AHeader/SearchMiddle"
import Footer from "../../../components/Website/core/Footer";
// import mixpanel from "mixpanel-browser";
// mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true });

export default {
  name: "TrendingCard",
  data() {
    return {
        value: 1,
        active: true,
        productList:[],
        skip: 0,
        itemsReturned: 10,
        hasNext: true,
        loadingTrending: false,
        fab: 2,
        sheet:false,
    };
  },
  methods:{
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    callPhone() {
      // <a href="tel://0553322008">
        window.location.href = 'tel://0553322008'
    },
    callUssd() {
      // <a href="tel://0553322008">
        window.location.href = 'tel://*713*83#'
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    nextPage(){
      this.skip++
      this.getProducts()
    },

    backPage(){
      this.skip--
      this.getProducts()
    },

    handleScrollToBottom(isVisible){
      if(!isVisible){return}
      if(!this.hasNext){return;}
      this.skip++
      this.getProducts()
    },

    getProducts(){
        this.loadingTrending = true
        gqlProduct.find(`{
          id
          name
          img_url
          lazy_url
          status
          weight
          units
          minimum
          usage
          price
          product_type{
              id
              name
              slug
          }
          category{
              id
              name
              slug
          }
          company{
            id
            name
            phone
            location
          }
          createdAt
        }`, {
          orderBy: "createdAt_DESC",
          first: this.itemsReturned,
          skip: this.skip * this.itemsReturned,
          where: {
            status_not: "deleted",
            company: {
              name: "HH Farms"
            }
          }
        })
          .then(res=>{
            this.productList.push(...res.data)
            this.hasNext = res.hasNext
          }).catch(err=>{
            this.$error('Error fetching trending products', err.message)
          }).finally(_=>{
            this.loadingTrending = false
          // mixpanel.track("Fetched Products", {
          //     products: 'scroll',
          // });
          })
      },
  },
  created(){
    this.getProducts();
  },
  watch:{
    isLoading:function (){
      if(this.isLoading()){
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    }
  },
  components: {
    MiniProductCard,
    NewProductCard,
    ProductCard,
    SearchMiddle,
    MiddleWare,
    Footer,
  }
};
</script>
<style scoped lang="scss">
.topFab{
    margin-bottom: -15px;
    z-index: 30;
}
.bottomFoot{
  background: white;
  border-radius: 30px;
  position:relative;
  bottom:40px;
}
.contact{
  font-weight: bold;
  font-size: 25px;
}
.spec {
  text-align: center;
  margin-bottom: 3em;
}
.spec h3 {
  font-size: 2em;
  color: #292929;
  margin-bottom: 0.5em;
}
.ser-t {
  text-align: center;
  margin: 0 auto;
  width: 20%;
  position: relative;
}
.ser-t b {
  width: 100%;
  height: 2px;
  display: block;
  background: #039445;
  margin-bottom: 3px;
}
.ser-t b.line {
  width: 80%;
  margin: 0 auto;
}
.ser-t span {
  width: 40px;
  height: 40px;
  display: block;
  background: #eeeeee;
  position: absolute;
  top: -7px;
  left: 42%;
}
.ser-t span i {
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #039445;
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -moz-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  -o-transform: rotate(45deg) translate3d(0, 0, 0);
  transform: rotate(45deg) translate3d(0, 0, 0);
  margin-left: 0.7em;
}

.box {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}
.bounce-4 {
  animation-name: bounce-4;
  animation-timing-function: ease;
}
@keyframes bounce-4 {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,.9) translateY(0); }
  30%  { transform: scale(.9,1.1) translateY(-10px); }
  50%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}


</style>
