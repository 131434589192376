<template>
  <div>
  <section>
      <v-layout>
        <!--        Center carousel-->
        <v-flex md12 sm12>
          <v-carousel
              cycle
              no-resize
              hide-delimiter-background
              hide-delimiters
              show-arrows-on-hover
              :height="$vuetify.breakpoint.width >= 768 ?  200 : 100"
          >
<!--            <v-carousel-item-->
<!--                src="https://res.cloudinary.com/akokomarket/image/upload/v1718382236/akokomarket-ecommerce/banners/2024_SALLAH_WEB_BANNER_wcadjq.jpg"-->
<!--                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1718382236/akokomarket-ecommerce/banners/2024_SALLAH_WEB_BANNER_wcadjq.jpg"-->
<!--                class="animate__animated animate__slideInRight animate_slideOutLeft"-->
<!--            >-->
<!--              <template v-slot:placeholder>-->
<!--                <v-row-->
<!--                    class="fill-height ma-0"-->
<!--                    align="center"-->
<!--                    justify="center"-->
<!--                >-->
<!--                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>-->
<!--                </v-row>-->
<!--              </template>-->
<!--            </v-carousel-item>-->
            <v-carousel-item
                replace
                src="https://res.cloudinary.com/akokomarket/image/upload/v1686831731/akokomarket-ecommerce/banners/chicken1_n2mgjp.jpg"
                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831731/akokomarket-ecommerce/banners/chicken1_n2mgjp.jpg"
                class="animate__animated animate__fadeIn animate__slow	2s"
            >
              <div style="height: 100%" class="animate__animated animate__fadeInUp animate__delay-1s text-container d-flex justify-start align-content-center">
                <div class="pl-8 mt-2 align-self-center">
                  <h2 class="text mb-n3" style="color: #fff;">BUY YOUR</h2>
                  <h2 class="text" style="color: #1A5E45; margin-top: 5px;">FRESH CHICKEN</h2>
                  <div class="animate__animated animate__fadeInDown animate__delay-2s">
                    <img src="@/assets/imgs/ghana_tag.jpg" style="width: 150px;"/>
                  </div>
                </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-carousel-item>

            <v-carousel-item
                replace
                src="https://res.cloudinary.com/akokomarket/image/upload/v1686831709/akokomarket-ecommerce/banners/eggs1_y6i6oa.jpg"
                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831709/akokomarket-ecommerce/banners/eggs1_y6i6oa.jpg"
                class="animate__animated animate__fadeIn animate__slow	2s"
            >
              <div style="height: 100%" class="animate__animated animate__fadeInUp animate__delay-1s text-container1 d-flex justify-end align-content-center">
               <div class="pr-8 mt-2 align-self-center">
                 <h2 class="text mb-n3" style="color: #fff;">BUY YOUR</h2>
                 <h2 class="text" style="color: #1A5E45; margin-top: 5px;">FRESH EGGS</h2>
                 <div class="animate__animated animate__fadeInDown animate__delay-2s">
                   <img src="@/assets/imgs/ghana_tag.jpg" style="width: 150px;"/>
                 </div>
               </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-carousel-item>

            <v-carousel-item
                replace
                src="https://res.cloudinary.com/akokomarket/image/upload/v1686831756/akokomarket-ecommerce/banners/maize1_cx98f3.jpg"
                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831756/akokomarket-ecommerce/banners/maize1_cx98f3.jpg"
                class="animate__animated animate__fadeIn animate__slow	2s"
            >
              <div style="height: 100%" class="animate__animated animate__fadeInUp animate__delay-1s text-container d-flex justify-start align-content-center">
                <div class="mt-2 pl-8 align-self-center">
                  <h2 class="text mb-n3" style="color: #fff;">BUY YOUR</h2>
                  <h2 class="text" style="color: #1A5E45; margin-top: 5px;">QUALITY MAIZE</h2>
                  <div class="animate__animated animate__fadeInDown animate__delay-2s">
                    <img src="@/assets/imgs/ghana_tag.jpg" style="width: 150px;"/>
                  </div>
                </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-carousel-item>

            <v-carousel-item
                replace
                src="https://res.cloudinary.com/akokomarket/image/upload/v1686831767/akokomarket-ecommerce/banners/soya1_dxhdko.jpg"
                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831767/akokomarket-ecommerce/banners/soya1_dxhdko.jpg"
                class="animate__animated animate__fadeIn animate__slow	2s"
            >
              <div style="height: 100%" class="animate__animated animate__fadeInUp animate__delay-1s text-container1 d-flex justify-end align-content-center">
                <div class="pr-8 mt-2 align-self-center">
                  <h2 class="text mb-n3 align-self-center" style="color: #ffffff;">BUY YOUR</h2>
                  <h2 class="text" style="color: #48311c; margin-top: 5px;">QUALITY SOYA</h2>
                  <div class="animate__animated animate__fadeInDown animate__delay-2s">
                    <img src="@/assets/imgs/ghana_tag.jpg" style="width: 150px;"/>
                  </div>
                </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-carousel-item>
            <v-carousel-item
                replace
                src="https://res.cloudinary.com/akokomarket/image/upload/v1699020945/akokomarket-ecommerce/banners/Shea_zqa822.jpg"
                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1699020945/akokomarket-ecommerce/banners/Shea_zqa822.jpg"
                class="animate__animated animate__fadeIn animate__slow	2s"
            >
              <div style="height: 100%" class="animate__animated animate__fadeInUp animate__delay-1s text-container d-flex justify-end align-content-center">
                <div class="pr-8 mt-2 align-self-center">
                  <h2 class="text mb-n3 align-self-center" style="color: #ffffff;">BUY YOUR</h2>
                  <h2 class="text" style="color: #673700; margin-top: 5px;">QUALITY SHEA NUT</h2>
                  <div class="animate__animated animate__fadeInDown animate__delay-2s">
                    <img src="@/assets/imgs/ghana_tag.jpg" style="width: 150px;"/>
                  </div>
                </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-carousel-item>
          </v-carousel>
        </v-flex>
      </v-layout>
   <search-middle></search-middle>
  </section>
  </div>
</template>

<script>
import SearchMiddle from "./core/AHeader/SearchMiddle"
// import ChipCategory from "../Website/Trending/ChipCategory"

export default {
  name: "Hero",
  components: {
    SearchMiddle,
  },
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4"
      ],
      slides: [
          {src:"116.png"},
          {src:"117.png"},
          {src:"111.png"},
          {src:"112.png"},
          {src:"113.png"},
          {src:"114.png"},
          {src:"115.png"},

      ],
      new_slides: [
        {
          src:"https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/maize_banner_w6epaj.jpg",
          lazy:"https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/maize_banner_w6epaj.jpg"
        },
        {
          src:"https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/soya_banner_gsdiwq.jpg",
          lazy:"https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/soya_banner_gsdiwq.jpg"
        },
        {
          src: "https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/chicken_banner_qydwmk.jpg",
          lazy: "https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/chicken_banner_qydwmk.jpg"
        },
        {
          src: "https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/eggs_banner_reswvn.jpg",
          lazy: "https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/eggs_banner_reswvn.jpg"
        }
      ],
      item: 1,
    };
  },
    computed:{
      categories(){
          return this.$store.getters.getCategories;
      },
    },
};
</script>

<style scoped>
  .pulse {
    cursor: pointer;
    border-radius:50px;
    box-shadow: 0 0 0 rgba(44, 215, 51, 0.4);
    animation: pulse 1s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(44, 215, 51, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
      box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(44, 215, 51, 0);
      box-shadow: 0 0 0 10px rgba(44, 215, 51, 0.1);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
      box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
    }
  }

  .pulse_red {
    cursor: pointer;
    border-radius:50px;
    box-shadow: 0 0 0 rgba(235, 98, 96, 0.4);
    animation: pulse 1s infinite;
  }

  .text {
    font-weight: bolder;
    font-size: 1rem;
    color: white;
    font-family: 'Clash Display' !important;
  }

  .text-container {
    position: absolute;
    left: 5%;
  }

  .text-container1 {
    position: absolute;
    right: 5%;
    text-align: end;
  }

  @-webkit-keyframes pulse_red {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(235, 98, 96, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
    }
  }
  @keyframes pulse_red {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
      box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(235, 98, 96, 0.1);
      box-shadow: 0 0 0 10px rgba(235, 98, 96, 0.1);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
      box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
    }
  }

</style>
