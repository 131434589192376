<template>
    <div>
        <product-modal :prodSet="prodSet" :dialog="prod_dialog"></product-modal>
        <v-card
                style="border-radius:15px"
                flat
                class="product_card mx-auto my-3"
                to=""
        >
                <v-img
                    :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=400`"
                    :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=20`"
                    height="200"
                >
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-card-title class="my-n4 text-center d-block">
                {{prodSet.name}}<br />
                <i class="caption">{{prodSet.product_type.name}}</i>
                <div class=" subtitle-2 black--text">
                    {{prodSet.price | currency}} {{prodSet.weight? ` / ${prodSet.weight}${prodSet.units}`: ''}} <i class="grey--text">
                </i>
                </div>
            </v-card-title>

            <v-card-text class="white">
                <div
                        class="text-center mx-0"
                >
                    <v-rating
                            :value="prodSet.rating"
                            color="amber"
                            dense
                            half-increments
                            readonly
                            size="10"
                    ></v-rating>

<!--                    <router-link to="/#" class="grey&#45;&#45;text text&#45;&#45;darken-3  font-weight-bold">-->
<!--                        <v-icon small color="orange">mdi-domain</v-icon>-->
<!--                        {{prodSet.company.name}}-->
<!--                    </router-link>-->

<!--                    <div class="grey&#45;&#45;text text&#45;&#45;darken-3 caption">-->
<!--                        <v-icon small color="red">mdi-map-marker</v-icon>-->
<!--                        {{prodSet.company.location}}-->
<!--                    </div>-->
<!--                    <div class="headline">-->
<!--                        <v-icon small color="green darken-2">mdi-phone-in-talk</v-icon>-->
<!--                        {{prodSet.agent_contact}}-->
<!--                      {{prodSet.company.phone}}-->

<!--                    </div>-->
                </div>
            </v-card-text>
            <v-card-actions class="text-center mt-n2">
                <v-btn
                        color="green darken-1"
                        outlined
                        rounded
                        block
                        @click="showDetails"
                >
                    Add to cart
                    <v-icon right>mdi-cart</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {eventBus} from "../../../main";
    import ProductModal from "./ProductModal";
    export default {
        name: "product_card",
        props:[
            'product_details'
        ],
        data: () => ({
            loading: false,
            selection: 1,
            prod_dialog:false,
        }),
        computed:{
             prodSet(){
                 return this.product_details;
             },
        },
        mounted(){
            eventBus.$on('product_modal',e=>{
                this.prod_dialog = e;
            })
            eventBus.$on('cart_add',e=>{
                this.prod_dialog = false;
            })
        },
        methods: {
            showDetails () {
                this.prod_dialog = true;
            },
        },

        components:{
            ProductModal,
        }
    }
</script>

<style scoped>
    .product_card{
        border:1px solid #e3e3e3;
        border-radius:10px 10px 10px 20px;
    }

</style>
