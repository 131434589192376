<template>
    <div style=" overflow-x: auto; overflow-y: hidden; white-space: nowrap" class="text-center px-n8 chip-scroll" >
            <v-chip
                :key="index"
                v-for="(chip, index) in productTypes"
                class="ma-2"
                :to="`/categories/${chip.id}`"
                style="background: #d8f3d7; color: #1c8016; height: 45px; border-radius: 10px; padding: 0px 20px;"
                :small="!!isMobile"
            >
                <!-- <v-avatar
                        class="mr-2 py-4">
                    <img
                            :src="`${imgHost}imgs/products/${chip.slug}/1.png?w=40`"
                            :lazy-src="`${imgHost}imgs/products/${chip.slug}/1.png?w=5`"
                            alt="chip"
                    >
                </v-avatar> -->
                {{chip.name}}
            </v-chip>
    </div>
</template>
<script>
    import Crud from '@/gql/PrismaCrud.gql'
    const productTypeGql = new Crud('productType')
    export default {
        name:"ChipCategory",
        data(){
            return{
                productTypes:[],
            }
        },
        computed:{
            chipSet(){
                return this.$store.getters.getCategories;
            },
        },
        mounted(){
            this.getProducts();
          console.log('hacker==>',this.chipSet)
        },
        methods: {
            getProducts(){
                productTypeGql.find(`{
                    id
                    name
                    slug
                }`).then(res=>{
                    this.productTypes = res.data
                })
            },
        },
    }
</script>

<style scoped>
    .chip-scroll{
        overflow-y: scroll;
        display: inline ruby;
        align-items: start;
        overflow-x: scroll;
        scrollbar-face-color: black;
    }
</style>
