<template>
    <v-card
      rounded="lg"
      @click="goToCategory(category)"
      elevation="0"
  >
    <v-img
      :src="require(`@/assets/imgs/${category.name}.jpeg`)"
      height="auto"
      style="position: relative;"
    >
    <v-card-title class="font-weight-bold text-sm-h5 text-subtitle-2" style="color: white">{{category.name}}</v-card-title>
    <div class="pb-2 pt-0 pl-2">
        <v-btn
          v-if="isMobile"
          x-small
          width="95"
          color="subsidiary"
          elevation="0"
          style="color: green; font-weight: 700; font-size: 8px; position: absolute; bottom: 10px; right: 3px; margin-right: 10px;"
        >
          Shop Now
          <v-icon left>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          color="subsidiary"
          elevation="0"
          style="color: green; font-weight: 700; position: absolute; bottom: 20px; right: 5px; margin-right: 10px;"
          v-else
        >
          Shop Now
          <v-icon left>mdi-chevron-right</v-icon>
        </v-btn>
    </div>
  </v-img>
  </v-card>
</template>

<script>
import mixpanel from 'mixpanel-browser';

mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true});


export default {
  name: "AgroCategoryCard",
  data(){
    return {
      link: "@/assets/imgs/"
    }
  },

  props: {
    category: Object
  },

  methods: {
    goToCategory(category) {
      mixpanel.track("Category Clicks", {
        category: `clicked_${category.name}`
      });
      this.$router.push({path: `categories/${category.id}`})
    }
  },
}
</script>

<style scoped>

</style>