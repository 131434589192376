<template>
  <div>
    <div>
      <v-img src="https://res.cloudinary.com/fitrip/image/upload/v1638204171/akokomarket/ussd_ad_strip_z4wxe7.png"/>
    </div>
    <div :class=" isMobile? 'spec mb-n7': 'spec'" >
      <p :style="[isMobile? {fontWeight: '400', color: '#878A86', fontSize: '16px', margin: 0} : {fontWeight: '400', color: '#878A86', fontSize: '24px'}]">{{headline}}</p>
    </div>
  </div>
</template>

<script>
    export default {
        name: "MiddleWare",
        props:[
            "headline"
        ],
        data(){
            return{

            }
        }
    }
</script>

<style scoped lang="scss">
    .spec {
        text-align: start !important;
        margin-bottom: 1em;
        margin-top: 15px;
        border-bottom: 2px solid #9ea2a2;
    }
    .spec h4 {
        font-size: 1.5em;
        color: #6c6e6e;
        margin-bottom: 0.5em;
    }
    .ser-t {
        text-align: start;
        margin: 0 auto;
        width: 20%;
        position: relative;
    }
    .ser-t b {
        width: 100%;
        height: 2px;
        display: block;
        background: #039445;
        margin-bottom: 3px;
    }
    .ser-t b.line {
        width: 80%;
        margin: 0 auto;
    }
    .ser-t span {
        width: 40px;
        height: 40px;
        display: block;
        background: #eeeeee;
        /*position: absolute;*/
        top: -7px;
        /*left: 42%;*/
    }
    .ser-t span i {
        width: 20px;
        height: 20px;
        display: block;
        border: 2px solid #039445;
        /*-webkit-transform: rotate(45deg) translate3d(0, 0, 0);*/
        /*-moz-transform: rotate(45deg) translate3d(0, 0, 0);*/
        /*-ms-transform: rotate(45deg) translate3d(0, 0, 0);*/
        /*-o-transform: rotate(45deg) translate3d(0, 0, 0);*/
        /*transform: rotate(45deg) translate3d(0, 0, 0);*/
        margin-left: 0.7em;
    }
</style>
