<template>
  <v-row>
    <v-col cols="12">
      <!-- <v-carousel
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
            height="500"
            :style="loading ? {display: 'block'} : {display: 'none'}"
          >
          <div>
              <div class="">
                <v-icon icon="fa:fas fa-circle-notch fa-spin" color="teal-darken-2"></v-icon>
              </div>
          </div>
        </v-carousel> -->

          <v-carousel
            cycle
            no-resize
            hide-delimiter-background
            hide-delimiters
            :height="$vuetify.breakpoint.width === 1280 ?  350 : 500"
            show-arrows-on-hover
          >
<!--            <v-carousel-item-->
<!--                src="https://res.cloudinary.com/akokomarket/image/upload/v1718382236/akokomarket-ecommerce/banners/2024_SALLAH_WEB_BANNER_wcadjq.jpg"-->
<!--                lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1718382236/akokomarket-ecommerce/banners/2024_SALLAH_WEB_BANNER_wcadjq.jpg"-->
<!--                class="animate__animated animate__slideInRight animate_slideOutLeft"-->
<!--            >-->
<!--              <template v-slot:placeholder>-->
<!--                <v-row-->
<!--                    class="fill-height ma-0"-->
<!--                    align="center"-->
<!--                    justify="center"-->
<!--                >-->
<!--                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>-->
<!--                </v-row>-->
<!--              </template>-->
<!--            </v-carousel-item>-->
            <v-carousel-item
              src="https://res.cloudinary.com/akokomarket/image/upload/v1686831731/akokomarket-ecommerce/banners/chicken1_n2mgjp.jpg"
              lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831731/akokomarket-ecommerce/banners/chicken1_n2mgjp.jpg"
              class="animate__animated animate__slideInRight animate_slideOutLeft"
            >
            <div class="animate__animated animate__fadeInUp animate__delay-1s text-container">
              <h1 class="text" style="color: #fff;">BUY YOUR</h1>
              <h1 class="text" style="color: #1A5E45; margin-top: -30px;">FRESH CHICKEN</h1>
              <div class="animate__animated animate__fadeInDown animate__delay-2s">
                <img src="@/assets/imgs/ghana_tag.jpg" style="width: 300px;"/>
              </div>
            </div>
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-carousel-item>

            <v-carousel-item
              src="https://res.cloudinary.com/akokomarket/image/upload/v1686831709/akokomarket-ecommerce/banners/eggs1_y6i6oa.jpg"
              lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831709/akokomarket-ecommerce/banners/eggs1_y6i6oa.jpg"
              class="animate__animated animate__slideInRight animate_slideOutLeft"
            >
            <div class="animate__animated animate__fadeInUp animate__delay-1s text-container1">
              <h1 class="text" style="color: #fff;">BUY YOUR</h1>
              <h1 class="text" style="color: #1c5844; margin-top: -30px;">FRESH EGGS</h1>
              <div class="animate__animated animate__fadeInDown animate__delay-2s">
                <img src="@/assets/imgs/ghana_tag.jpg" style="width: 300px;"/>
              </div>
            </div>
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-carousel-item>

            <v-carousel-item
              src="https://res.cloudinary.com/akokomarket/image/upload/v1686831756/akokomarket-ecommerce/banners/maize1_cx98f3.jpg"
              lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831756/akokomarket-ecommerce/banners/maize1_cx98f3.jpg"
              class="animate__animated animate__slideInRight animate_slideOutLeft"
            >
            <div class="animate__animated animate__fadeInUp animate__delay-1s text-container">
              <h1 class="text" style="color: #fff;">BUY YOUR</h1>
              <h1 class="text" style="color: #1A5E45; margin-top: -30px;">QUALITY MAIZE</h1>
              <div class="animate__animated animate__fadeInDown animate__delay-2s">
                <img src="@/assets/imgs/ghana_tag.jpg" style="width: 300px;"/>
              </div>
            </div>
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-carousel-item>

            <v-carousel-item
              src="https://res.cloudinary.com/akokomarket/image/upload/v1686831767/akokomarket-ecommerce/banners/soya1_dxhdko.jpg"
              lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686831767/akokomarket-ecommerce/banners/soya1_dxhdko.jpg"
              class="animate__animated animate__slideInRight animate_slideOutLeft"
            >
            <div class="animate__animated animate__fadeInUp animate__delay-1s text-container1">
              <h1 class="text" style="color: #ffffff;">BUY YOUR</h1>
              <h1 class="text" style="color: #48311c; margin-top: -30px;">QUALITY SOYA</h1>
              <div class="animate__animated animate__fadeInDown animate__delay-2s">
                <img src="@/assets/imgs/ghana_tag.jpg" style="width: 300px;"/>
              </div>
            </div>
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-carousel-item>

            <v-carousel-item
              src="https://res.cloudinary.com/akokomarket/image/upload/v1699020945/akokomarket-ecommerce/banners/Shea_zqa822.jpg"
              lazy-src="https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1699020945/akokomarket-ecommerce/banners/Shea_zqa822.jpg"
              class="animate__animated animate__fadeIn animate__slow	2s"
            >
            <div class="animate__animated animate__fadeInUp animate__delay-1s text-container">
              <h1 class="text" style="color: #ffffff;">BUY YOUR</h1>
              <h1 class="text" style="color: #673700; margin-top: -30px;">QUALITY SHEA NUT</h1>
              <div class="animate__animated animate__fadeInDown animate__delay-2s">
                <img src="@/assets/imgs/ghana_tag.jpg" style="width: 300px;"/>
              </div>
            </div>
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-carousel-item>
          </v-carousel>
    </v-col>
    <v-col cols="12">
      <search-middle></search-middle>
    </v-col>
  </v-row>
</template>

<script>
import SearchMiddle from "./core/AHeader/SearchMiddle"
// import ChipCategory from "../Website/Trending/ChipCategory"

export default {
  name: "Hero",
  components: {
    SearchMiddle,
    // ChipCategory,
  },
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4"
      ],
      loading: true,
      slides: [
          {src:"116.png"},
          {src:"117.png"},
          {src:"111.png"},
          {src:"112.png"},
          {src:"113.png"},
          {src:"114.png"},
          {src:"115.png"},
      ],
      new_slides: [
          {
            src:"https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/maize_banner_w6epaj.jpg",
            lazy:"https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/maize_banner_w6epaj.jpg"
          },
          {
            src:"https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/soya_banner_gsdiwq.jpg",
            lazy:"https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/soya_banner_gsdiwq.jpg"
          },
          {
            src: "https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/chicken_banner_qydwmk.jpg",
            lazy: "https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/chicken_banner_qydwmk.jpg"
          },
          {
            src: "https://res.cloudinary.com/akokomarket/image/upload/v1686308849/akokomarket-ecommerce/banners/eggs_banner_reswvn.jpg",
            lazy: "https://res.cloudinary.com/akokomarket/image/upload/c_thumb,w_200,g_face/v1686308849/akokomarket-ecommerce/banners/eggs_banner_reswvn.jpg"
          }
      ],
      item: 1,
      trending_com:[
        {
          id:1,
          company:"Agroinnova Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },
      ],
    };
  },
  methods: {
    onImageTimer () {
      setTimeout(() => {
        console.log("------------------I delayed for 2000")
        this.loading = false
      }, 12000)
    }
  },
    computed:{
      categories(){
          return this.$store.getters.getCategories;
      },
    },
  mounted(){
    this.onImageTimer();
  }
};
</script>

<style scoped>
  .pulse {
    cursor: pointer;
    border-radius:50px;
    box-shadow: 0 0 0 rgba(44, 215, 51, 0.4);
    animation: pulse 1s infinite;
  }

  .text {
    font-weight: bolder;
    font-size: 4rem;
    color: white;
    font-family: 'Clash Display' !important;
  }

  .text-container {
    position: absolute;
    top: 33%;
    left: 20%;
  }

  .text-container1 {
    position: absolute;
    top: 33%;
    right: 20%;
    text-align: end;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(44, 215, 51, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
      box-shadow: 0 0 0 0 rgba(44, 215, 51, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(44, 215, 51, 0);
      box-shadow: 0 0 0 10px rgba(44, 215, 51, 0.1);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
      box-shadow: 0 0 0 0 rgba(44, 215, 51, 0);
    }
  }

  .pulse_red {
    cursor: pointer;
    border-radius:50px;
    box-shadow: 0 0 0 rgba(235, 98, 96, 0.4);
    animation: pulse 1s infinite;
  }

  @-webkit-keyframes pulse_red {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(235, 98, 96, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
    }
  }
  @keyframes pulse_red {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
      box-shadow: 0 0 0 0 rgba(235, 98, 96, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(235, 98, 96, 0.1);
      box-shadow: 0 0 0 10px rgba(235, 98, 96, 0.1);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
      box-shadow: 0 0 0 0 rgba(235, 98, 96, 0);
    }
  }

</style>
