<template>
  <div v-if="isMobile" class="mb-2 search-middle-mini">
    <div class="d-flex align-center " style="height: 91px; width: auto">
      <auto-search outlineColor="white" type="mini"></auto-search>
    </div>
<!--    <div style="height: 120px; width: auto;" class="px-4 white">-->
<!--&lt;!&ndash;      <div class="d-flex justify-space-between">&ndash;&gt;-->
<!--&lt;!&ndash;        <p  style="font-size: 12px;" class="pt-4 primary&#45;&#45;text font-weight-bold">browse categories</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p  style="font-size: 12px;" class="pt-4 primary&#45;&#45;text font-weight-bold">>></p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <chip-category class="mt-0"></chip-category>&ndash;&gt;-->
<!--    </div>-->
  </div>
  <div v-else class="search-middle">
      <v-row class="mx-0 px-0 mb-2" align="center">
          <auto-search outlineColor="green" type="large" class="mt-0"></auto-search>
      </v-row>
      <v-row justify="center" justify-md="center">
<!--          <chip-category class="mt-4 mx-n4"></chip-category>-->
      </v-row>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import AutoSearch from "@/components/Website/AutoSearch";
import ChipCategory from "../../../Website/Trending/ChipCategory"

export default {
  name: "SearchMiddle",
  data() {
    return {
      menu:false,
      searchMenu:false,
      websiteToolbar: true,
      adminToolbar: false,
      items: [
        { text: "Fresh Eggs", icon: " mdi-circle-small" },
        { text: "Local Poultry", icon: " mdi-circle-small" },
        { text: "Yellow Maize", icon: " mdi-circle-small" },
        { text: "Poultry Drugs", icon: " mdi-circle-small" },
        { text: "Poultry Equipment", icon: " mdi-circle-small" },
        { text: "Day Old Chicks", icon: " mdi-circle-small" },
        { text: "Poultry Feed", icon: " mdi-circle-small" },
        { text: "Feed Mill", icon: " mdi-circle-small" },
        { text: "Veterinary Services", icon: " mdi-circle-small" },
        { text: "Other Livestock", icon: " mdi-circle-small" },
      ],
    };
  },

  watch: {
    $route(val) {
      this.title = val.name;
    },
  },
  computed:{
    ...mapGetters(['loginState','cartMenu']),
    breakPoint(){
      return this.$vuetify.breakpoint
    },
    noItems(){
      return this.$store.getters.getCart.length;
    },
  },
  mounted() {
      // console.log(this.$vuetify.breakpoint)
      console.log(this.userData)
  },
  beforeDestroy() {},

  methods: {
    clearLoader(){
      this.$store.commit("setLoading",false);
    },
    getToken(){
      localStorage.removeItem('token');
      this.$store.dispatch('getToken')
              .then((response) =>{
                localStorage.setItem('token', response);
                alert(response);
                console.log(response);
              })
              .catch(err => {
                self.loader = false;
                console.log(err)
              })
    },
    logoutUser(){
      let self = this;
        this.$store.dispatch("auth/logoutUser").then(e=>{
          self.$store.commit('setSnack', { color: 'error', status_msg: 'Logged Out', added_msg: 'User Logged Out.' })
        });
    },
  },
  components:{
    AutoSearch,
    ChipCategory,
  }
};
</script>

<style>
#core-app-bar a {
  text-decoration: none;
}
.search-middle {
  background: white;
  padding: 30px;
  bottom: 60px;
  width: 78%;
  border-radius: 10px;
  margin: 25px auto !important;
}

.search-middle-mini {
  background: white;
  padding: 20px;
  bottom: 40px;
  border-radius: 10px;
  margin: 25px auto !important;
}

</style>
