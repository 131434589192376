<template>
  <v-container fluid style="width: 80%;">
    <v-row>
      <v-col
          cols="6"
          md="3"
          v-for="cate in agroCategories"
          :key="cate.id"
          class="px-5"
      >
        <agro-category-card
            :category="cate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col  class="mt-3 mt-md-10 mb-5 mb-md-0">
        <v-img
          style="border-radius: 10px;"
          src="../../../assets/imgs/AkokoEcom.jpg"
          class="d-flex  align-md-center"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixpanel from "mixpanel-browser"
import Crud from "@/gql/PrismaCrud.gql";
import AgroCategoryCard from "@/components/Website/AgroCategories/AgroCategoryCard.vue";


const gqlCategory = new Crud('productType')

export default {
  name: "AgroCategoriesGroup",

  components: {
    AgroCategoryCard
  },

  data(){
    return{
      agroCategories: []
    }
  },

  methods: {
    getAgroCategories(){
      gqlCategory.find(`{
          id
          name
          slug
        }`, {
        orderBy: "createdAt_DESC",

      })
          .then(res=>{
            let cateArr = res.data;
            cateArr.push(cateArr.splice(0, 1)[0])
            this.agroCategories = cateArr;
          }).catch(err=>{
        this.$error('Error Fetching Categories', err.message)
      }).finally(_=>{
      })
    },
  },

  mounted() {
    this.getAgroCategories()

  }
}
</script>

<style scoped>

</style>